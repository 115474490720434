import { useMemo } from 'react';
import useAxios from 'core/hooks/useAxios';

export default function useMakes(year) {
  // only call api when prop changes
  const { response: makesResponse, loading } = useAxios({
    url: '/v1/towing/trucks',
    method: 'POST',
    options: { 
      data: {
        year
      }
    },
    trigger: year || undefined,
  });

  const makesData = makesResponse?.data?.data;

  // only sort and map makes data when var changes
  const makes = useMemo(() => {
    if (!makesData) return [];
    return makesData.sort().map(make => ({
      value: make,
      label: make,
    }));
  }, [makesData]);

  return { makes, makesLoading: loading };
}