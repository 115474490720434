import { useMemo } from 'react';
import useAxios from 'core/hooks/useAxios';

export default function useModels(year, make) {
  // only call api when prop changes
  const { response: modelsResponse, loading } = useAxios({
    url: '/v1/towing/trucks',
    method: 'POST',
    options: { 
      data: {
        year,
        make
      }
    },
    trigger: year && make
    ? JSON.stringify(year + make)
    : undefined
  });

  const modelsData = modelsResponse?.data?.data;

  // only sort and map models data when var changes
  const models = useMemo(() => {
    if (!modelsData) return [];
    return modelsData.sort().map(model => ({
      value: model,
      label: model,
    }));
  }, [modelsData]);

  return { models, modelsLoading: loading };
}