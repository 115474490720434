/* eslint-disable no-prototype-builtins */
import React from 'react';
import Downshift from 'downshift';
import matchSorter from 'match-sorter';

import Icon from 'core/ui/Icon';

import {
  StyledTextInput,
  Ul,
  Li,
  ControllerButton,
  XIcon,
  ArrowIcon,
  ButtonSelected,
} from './styles';

// make sure items prop is default as an empty array to avoid array errors
export const DownshiftAutocomplete = props => {
  const {
    items = [],
    handleChange,
    selectedValue,
    error,
    disabled,
    hideClearSelection,
    handleFieldTouch,
    placeholder,
    getLabelProps,
    customValuePrefix,
    icon,
    isCustomValueAllowed = false,
    className,
    containerStyles,
    id,
    ...rest
  } = props;

  function getItems(setItems, filter) {
    return filter
      ? matchSorter(setItems, filter, {
          keys: ['value'],
        })
      : setItems;
  }

  // set initial input value in local state, defaulted to whatever might be saved on first render
  const [fieldValue, setValue] = React.useState(selectedValue?.value || '');

  const inputRef = React.useRef(null);

  const handleCustomSelect = handleCloseMenu => {
    // when a user explicitly clicks their custom option, call handleChange to set it in our parent's state
    if (fieldValue && fieldValue.trim()) {
      handleChange({
        name: rest.name,
        value: fieldValue,
        isCustom: true,
        inputRef,
      });
    }

    handleCloseMenu();
  };

  const handleStateChange = changes => {
    if (changes?.type === Downshift.stateChangeTypes.controlledPropUpdatedSelectedItem) {
      // if we set a field's value outside of the component, this event is fired
      // therefore we just want to set our internal state with that new value
      setValue(changes.selectedItem);
      return;
    }

    // dataset options return both selectedItem and inputValue properties
    if (changes.hasOwnProperty('selectedItem') && changes.hasOwnProperty('inputValue')) {
      // set both our parent's state AND our local state w/ the selected value
      handleChange({
        name: rest.name,
        value: changes.selectedItem,
        isCustom: false,
        inputRef,
      });
      setValue(changes.selectedItem);
    } else if (changes.hasOwnProperty('inputValue')) {
      // set our local state when they are typing.
      setValue(changes.inputValue);
    } else if (changes.hasOwnProperty('selectedItem') && isCustomValueAllowed) {
      // on keydown for custom values calls handleStateChange but only returns selectedItem property
      handleChange({
        name: rest.name,
        value: changes.selectedItem,
        isCustom: true,
        inputRef,
      });
      setValue(changes.selectedItem);
    } else if (changes.hasOwnProperty('selectedItem') && !isCustomValueAllowed) {
      handleChange({
        name: rest.name,
        value: changes.selectedItem,
      });
      setValue(changes.selectedItem);
    }
  };

  const itemToString = val => (typeof val === 'string' ? val : val?.value || '');

  return (
    <div style={{ position: 'relative', ...containerStyles }}>
      <Downshift
        selectedItem={selectedValue}
        onStateChange={handleStateChange}
        itemToString={itemToString}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          getToggleButtonProps,
          isOpen,
          inputValue,
          highlightedIndex,
          clearSelection,
          selectedItem,
          openMenu,
          closeMenu,
        }) => {
          const optionsList = getItems(items, inputValue);

          const trimmedInputValue = (inputValue || '').trim();

          const customValueButton = () => {
            const customIndex = optionsList?.length || 0;
            const customOptionInList = (optionsList || []).some(
              opt =>
                (opt?.value || '')
                  .toString()
                  .toLowerCase()
                  .trim() === trimmedInputValue.toLowerCase()
            );

            // if the input has a value AND the inputted value is not already in the list
            return trimmedInputValue && !customOptionInList && isCustomValueAllowed ? (
              <Li
                role="option"
                data-testid={`${id}_item_${customIndex}`}
                {...getItemProps({
                  index: customIndex,
                  item: inputValue,
                  active: highlightedIndex === customIndex,
                  selected: selectedItem === inputValue,
                  onClick: () => handleCustomSelect(closeMenu),
                })}
              >
                {customValuePrefix ? `${customValuePrefix} ` : ''}
                {inputValue}
              </Li>
            ) : null;
          };

          const listItems = optionsList.map((item, index) => (
            <Li
              key={item.value}
              data-testid={`${id}_item_${index}`}
              {...getItemProps({
                index,
                item: item.value,
                active: highlightedIndex === index,
                selected: selectedItem === item.value,
              })}
            >
              {item.value}
            </Li>
          ));

          return (
            <div>
              {icon && (
                <ControllerButton
                  hasIcon={icon}
                  type="button"
                  aria-label="toggle menu"
                  disabled={disabled}
                  {...getToggleButtonProps()}
                >
                  <Icon icon={icon} />
                </ControllerButton>
              )}

              {!!selectedItem?.name && !isOpen ? (
                <ButtonSelected
                  disabled={disabled}
                  hasIcon={icon}
                  type="button"
                  link
                  onClick={openMenu}
                >
                  {selectedItem.name}
                </ButtonSelected>
              ) : (
                <StyledTextInput
                  hasIcon={icon}
                  invalid={error}
                  onClick={openMenu}
                  ref={inputRef}
                  height={rest.inputHeight}
                  placeholder={placeholder || ''}
                  data-testid={id}
                  {...getInputProps({
                    isOpen,
                    disabled,
                    onBlur: () => {
                      if (typeof handleFieldTouch === 'function') {
                        handleFieldTouch(rest.name);
                      }
                    },
                  })}
                />
              )}

              {!hideClearSelection && selectedItem ? (
                <ControllerButton
                  type="button"
                  onClick={clearSelection}
                  aria-label="clear selection"
                >
                  <XIcon />
                </ControllerButton>
              ) : (
                <ControllerButton
                  type="button"
                  aria-label="toggle menu"
                  {...getToggleButtonProps({
                    disabled,
                  })}
                >
                  <ArrowIcon isOpen={isOpen} />
                </ControllerButton>
              )}

              <Ul {...getMenuProps()} data-testid={`autocomplete-list-${rest.name}`}>
                {isOpen ? (
                  <>
                    {customValueButton()}
                    {listItems}
                  </>
                ) : null}
              </Ul>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
};
