import React from 'react';

import { Tooltip } from 'components/Tippy';
import { Button } from 'core/ui';
import Icon from 'core/ui/Icon';
import styled from 'styled-components';

const noop = () => { };

const TooltipButton = styled(Button)`
  background-color: transparent;
  color: #6C757D;

  &:hover {
    color: #414141;
  }
`;

function ExplanationPopover({
  icon = null,
  content,
  trigger = 'mouseenter focus',
  offset = 0,
  placement = 'top',
  buttonStyle,
  interactive = false,
  onShown = noop,
  size,
}) {
  return (
    <Tooltip
      content={content}
      theme="light-border"
      interactive={interactive}
      trigger={trigger}
      offset={offset}
      placement={placement}
      duration={100}
      onShown={onShown}
    >
      <TooltipButton
        type="button"
        px="7px"
        py="0"
        style={buttonStyle}
        aria-label="Explain"
        data-event-category="RV Details Page"
        data-event-action={content}
      >
        {icon || <Icon icon={['far', 'question-circle']} size={size} />}

      </TooltipButton>
    </Tooltip>
  );
}
export default ExplanationPopover;
