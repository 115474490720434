import useAxios from 'core/hooks/useAxios';

export default function useDataset(year, make, model) {
  const { response: datasetResponse, loading } = useAxios({
    url: '/v1/towing/trucks',
    options: { 
      data: {
        year,
        make,
        model
      }
    },
    method: 'POST',
    trigger: year && make && model ? JSON.stringify(year + make + model) : undefined,
  });

  const datasetData = datasetResponse?.data?.data;

  return {
    data: datasetData,
    dataLoading: loading,
    hasNoMatch: datasetResponse?.status === 200 && !datasetData,
  };
}
