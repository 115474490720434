import { useEffect, useReducer } from 'react';
import once from 'lodash/once';
import axios from 'axios';

import { getStateFromDOM } from '../utils/client';

export const getApiHost = once(() => getStateFromDOM('env.apiHost'));

export const initialResponse = { response: null, error: null, loading: false };

export function responseReducer(state, action) {
  switch (action.type) {
    case 'init':
      return { response: null, error: null, loading: true };
    case 'success':
      return { response: action.payload, error: null, loading: false };
    case 'fail':
      return { response: null, error: action.payload, loading: false };
    default:
      return initialResponse;
  }
}

/**
 * Params
 * @param  {string} url - The request URL
 * @param  {('GET'|'POST'|'PUT'|'DELETE'|'HEAD'|'OPTIONS'|'PATCH')} method - The request method
 * @param  {object} [options={}] - (optional) The config options of Axios.js (https://goo.gl/UPLqaK)
 * @param  {object|string} trigger - (optional) The conditions for AUTO RUN, refer the concepts of [conditions](https://reactjs.org/docs/hooks-reference.html#conditionally-firing-an-effect) of useEffect, but ONLY support string and plain object. If the value is a constant, it'll trigger ONLY once at the beginning
 */

/**
 * Returns
 * @param  {object} response - The response of Axios.js (https://goo.gl/dJ6QcV)
 * @param  {object} error - HTTP error
 * @param  {boolean} loading - The loading status
 */

const { CancelToken } = axios;

const defaultOptions = {
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
};

export default ({
  url,
  method = 'get',
  options = {},
  trigger,
} = {}) => {
  const [results, dispatch] = useReducer(responseReducer, initialResponse);

  // only redo call when "trigger" changes
  let triggerString = trigger;
  try {
    triggerString = JSON.stringify(trigger);
  } catch (err) {
    //
  }

  useEffect(() => {
    if (!url) return;
    // ONLY trigger by query
    if (typeof triggerString === 'undefined') return;

    dispatch({ type: 'init' });

    const source = CancelToken.source();

    axios({
      ...defaultOptions,
      ...options,
      // Allow baseURL to be passed in, but default to API host
      baseURL: options.baseURL || getApiHost(),
      url,
      method,
      cancelToken: source.token,
    })
      .then((response) => {
        dispatch({ type: 'success', payload: response });
      })
      .catch((error) => {
        if (!axios.isCancel(error)) {
          dispatch({ type: 'fail', payload: error });
        }
      });

    // When the trigger changes, cancel the previous call
    // before making another one
    return () => {
      source.cancel();
    };
  // only redo call when "trigger" changes
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerString]);

  return results;
};
