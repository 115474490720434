/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

export const Disclaimer = styled.p`
  font-size: 0.75rem;
  margin-bottom: ${props => props.marginBottomNeeded ? '1rem' : 0};
  color: #414141;
`;

function ModelYearDisclaimer({
  year,
  marginBottomNeeded
}) {
  const covidYears = ['2022'];
  const [showYearDisclaimer, setShowYearDisclaimer] = useState(false);


  useEffect(() => {
    setShowYearDisclaimer(covidYears.some(v => v === year));
  }, [year, covidYears]);

  return (
    !showYearDisclaimer ? null :
    <Disclaimer marginBottomNeeded={marginBottomNeeded}>
      Due to COVID and supply chain issues, vehicle manufacturers have not released updated
      specs for 2022 vehicles. Vehicle matching for these years uses 2021 vehicle data.
    </Disclaimer>
  );
}

export default ModelYearDisclaimer;
