import React from 'react';
import styled from 'styled-components';

import { TextInput } from 'form-fields/styled/styles';
import { Button as BaseButton } from 'core/ui';

export const StyledTextInput = styled(TextInput)`
  color: #1c1c11;
  border: 1px solid #d9d9d9;
  box-shadow: 0px 0px 0px 1px #d9d9d9;
  font-size: 1rem;
  border-radius: 4px;
  height: ${props => (props.height ? props.height : '50px')};
  padding-left: ${props => props.hasIcon && '32px'};

  ::placeholder {
    color: #1c1c11;
    opacity: 1;
  }

  ${({ theme }) => theme.medium`
    font-size: 1rem;
  `};
`;

export const Li = styled.li`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: none;
  height: auto;
  text-align: left;
  border-top: none;
  line-height: 1em;
  font-size: 1rem;
  text-transform: none;
  font-weight: 400;
  box-shadow: none;
  padding: 0.8rem 1.1rem;
  white-space: normal;
  word-wrap: normal;
  color: ${props =>
    props.active || props.selected ? 'rgba(0, 0, 0, 0.95)' : 'rgba(0, 0, 0, 0.87)'};
  background-color: ${props => (props.active ? '#f4f4f4' : 'transparent')};
  font-weight: ${props => (props.selected ? '700' : 'normal')};
`;

export const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 0;
  position: absolute;
  background-color: #fff;
  width: 100%;
  max-height: 20rem;
  overflow-y: auto;
  overflow-x: hidden;
  outline: 0;
  transition: opacity 0.1s ease;
  border-radius: 0 0 0.29rem 0.29rem;
  box-shadow: 0 2px 3px 0 rgba(34, 36, 38, 0.15);
  border-color: ${props => props.theme.colors.lightGrey};
  border-top-width: 0;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-style: solid;
  ${props => (props.isOpen ? '' : 'border: none;')};
  z-index: 15;
`;

export const ControllerButton = styled.button`
  background-color: transparent;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  left: ${props => props.hasIcon && '0'};
  cursor: pointer;
  width: ${props => (props.hasIcon ? '32px' : '47px')};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

export const Loading = styled.span`
  background-color: #fff;
  padding-left: 5px;
  margin: 5px;
  color: #979797;
`;

export function ArrowIcon({ isOpen }) {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={14}
      fill="transparent"
      stroke="#d9d9d9"
      strokeWidth="5.1px"
      transform={isOpen ? 'rotate(180)' : undefined}
    >
      <path d="M1,6 L10,15 L19,6" />
    </svg>
  );
}

export function XIcon() {
  return (
    <svg
      viewBox="0 0 20 20"
      preserveAspectRatio="none"
      width={12}
      fill="transparent"
      stroke="#d9d9d9"
      strokeWidth="1.1px"
    >
      <path d="M1,1 L19,19" />
      <path d="M19,1 L1,19" />
    </svg>
  );
}

export const ButtonSelected = styled(BaseButton)`
  width: 90px;
  text-align: left;
  border: 1px solid ${props => props.theme.colors.gray400};

  &:hover {
    border: 1px solid ${props => props.theme.colors.gray400};
  }

  img {
    vertical-align: text-bottom;
  }
`;
